import type { PricingPlanDTO, Type } from './ParagraphPricing.domain';

import type { FC } from 'react';
import { Pricing } from '@genially/public-web-components';
import { mapPricingPlans } from './mappers/mapPricingPlans';
import { snowplowWrapper } from 'core/services/snowplow';

interface ParagraphPricingProps {
  langcode: string;
  plans: PricingPlanDTO[];
  components: any;
  types: Type[];
}

export const ParagraphPricing: FC<ParagraphPricingProps> = ({
  langcode,
  plans,
  components,
  types,
}) => {
  const sendPageView = () => {
    // Snowplow tracking for all pages and all cases
    snowplowWrapper.initTracker();
    snowplowWrapper.sendPageViewEvent();
  };
  // Minimum delay for reactHelmet's requestAnimationFrame
  setTimeout(sendPageView, 32);
  return (
    <Pricing
      snowplowEvent={sendPageView}
      plans={mapPricingPlans(plans, true, langcode)}
      types={types}
      components={components}
    />
  );
};
