import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { CustomSpacing, Jobs, theme } from '@genially/public-web-components';

import type { FC } from 'react';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import { parseMedia } from 'core/infrastructure/parseMedia';
import { translate } from 'core/infrastructure/translator';

type Color = keyof typeof theme.colors;

interface ParagraphJobsProps {
  behaviorSettings?: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  langcode: string;
  title?: string;
  titleColor?: Color;
  custom?: {
    data: {
      content: {
        count: number;
        entities: ParagraphJobsListDTO[];
      };
    };
  };
  entities?: ParagraphJobsListDTO[];
}

export interface ParagraphJobsListDTO {
  title?: string;
  departments: {
    department: {
      tid: number;
      title: string;
      media: MediaImageDTO;
    };
  };
  link?: {
    url: {
      path: string;
    };
  };
}

export const ParagraphJobs: FC<ParagraphJobsProps> = ({
  behaviorSettings,
  title,
  titleColor,
  langcode,
  custom,
}) => {
  const jobList = mapperJobList(custom?.data.content.entities);

  return (
    <Jobs
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      title={
        title
          ? {
              text: title,
              color: titleColor,
            }
          : undefined
      }
      totalJobOffers={
        custom?.data?.content?.count
          ? getTotalJobOffers(custom?.data?.content?.count, jobList.length, langcode)
          : undefined
      }
      jobsList={jobList}
    />
  );
};

export interface JobList {
  category: string;
  media: any;
  offers: { title: string; url: string }[];
}

export const mapperJobList = (entities: ParagraphJobsListDTO[] = []): JobList[] => {
  if (!entities) return [];

  const jobList: JobList[] = [];

  entities.forEach(entity => {
    const jobCategory = jobList.find(job => job.category === entity.departments.department.title);
    const offer = { title: entity.title || '', url: entity.link?.url.path || '' };

    if (jobCategory) {
      jobCategory.offers.push(offer);
    } else {
      jobList.push({
        category: entity.departments.department.title,
        media: parseMedia(entity.departments.department.media),
        offers: [offer],
      });
    }
  });

  return jobList;
};

export const getTotalJobOffers = (
  offers: number,
  departments: number,
  langcode: string
): string => {
  return (
    translate(langcode, 'jobs.totalJobOffers')
      .replace('@totalJobs', offers.toString() || '')
      .replace('@totalDepartments', departments.toString()) || ''
  );
};
