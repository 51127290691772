import Cookies from 'universal-cookie';

interface Translation {
  title?: string;
  language: {
    id: string;
  };
  url: {
    path: string;
  };
  onClick: () => void;
}

const HOME_URL = '/';
const DEFAULT_LANGUAGE = 'en';

const setLanguageCookie = (langCode: string) => {
  const cookies = new Cookies();
  const expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 3);
  cookies.set('gen.lang', langCode, {
    path: '/',
    domain: '.genially.com',
    expires: expireDate,
  });
};
export const createLanguageDropdownOptions = (
  listLanguageOptions: Array<Record<string, string>>,
  content: Record<string, any>
): Array<{ activeValue: string; url: string; value: string; onClick: void }> => {
  return listLanguageOptions.map((element: Record<string, string>) => {
    // clone and avoid mutability
    const listLanguageOption = JSON.parse(JSON.stringify(element));

    listLanguageOption.onClick = () => {
      setLanguageCookie(listLanguageOption.langcode.toLowerCase());
    };

    const currentTranslation = content.translations?.find((translation: Translation) => {
      if (listLanguageOption.url === HOME_URL) {
        return DEFAULT_LANGUAGE;
      }
      return listLanguageOption.url.includes(translation.language.id);
    });

    if (!currentTranslation) {
      return listLanguageOption;
    }

    listLanguageOption.url = currentTranslation.url?.path;

    return listLanguageOption;
  });
};
